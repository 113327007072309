import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrl: './activate-account.component.scss'
})
export class ActivateAccountComponent {
  /**
   * Form with OTP input field where the otp will be sent
   */
  otpForm: FormGroup;
  /**
   * Used for form validation and displaying validations errors
   */
  submitted = false;
  /**
   * Used to display spinner when the register button is clicked
   */
  isLoading = false;
  /**
   * Email obtained from the reset password link sent to the user's email
   */
  email: string;

  /**
   * Injects dependencies into the component.
   * Adds dependencies to the component
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   */

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private activatedRoute: ActivatedRoute
  ) { }

  /**
   * Initalizes the otpForm with the default values when the component is initialized
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.email = params['email'];
      }
    );
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required]
    });
  }

  /**
   * Getter for all the controls of the otpForm
   */
  get f(): FormGroup['controls'] {
    return this.otpForm.controls;
  }

  /**
   * Sends OTP for the account to be activated
   */
  sendOTP(): void {
    this.submitted = true;

    if (this.otpForm.invalid) {
      return;
    }
    
    const data = {
      email: this.email,
      otp: this.otpForm.get('otp')?.value
    };

    this.isLoading = true;

    this.api.postNoAuth('/users/activate-account', JSON.parse(JSON.stringify(data))).subscribe(
      {
        next: res => {
          if (res.status_code === 200) {
            this.otpForm.reset();
            this.isLoading = false;
            this.submitted = false;
            this.notify.showSuccess('Account has been activated. You can now log in.');
          } else {
            this.notify.showError(res.detail);
            this.isLoading = false;
          }
        },
        error: (e) => {
          this.notify.showError(e);
          this.isLoading = false;
        }
      }
    );
  }
}
