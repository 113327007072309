<div class="reset">
    <div class="container">
        <form [formGroup]="otpForm">
            <div class="form-group col-md-6">
                <label for="otp">OTP</label>
                <input type="text" class="form-control" formControlName="otp"
                    [ngClass]="{ 'is-invalid': submitted && f['otp'].errors}" autofocus>
                <div *ngIf="submitted && f['otp'].errors" class="invalid-feedback">
                    <div *ngIf="f['otp']['errors']required">OTP is required</div>
                </div>
            </div>
            <div class="form-group col-md-6 buttons">
                <button class="btn btn-info btn-block" (click)="sendOTP()" [disabled]="isLoading">
                    ACTIVATE ACCOUNT
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </div>
        </form>
    </div>
</div>