import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '../../../shared/custom-validators/mustMatch';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';

/**
 * Reset password component allows the user to reset their password.
 */
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  /**
   * Used for form validation and displaying validations errors
   */
  submitted = false;
  /**
   * Form with input field for new password
   */
  passwordForm: FormGroup;
  /**
   * Used to display spinner when the register button is clicked
   */
  isLoading: boolean;
  /**
   * Used on the password fields to hide or show passwords
   */
  fieldTextType: boolean;
  /**
   * Token obtained from the reset password link sent to the user's email
   */
  token: string;
  /**
   * User ID obtained from the reset password link sent to the user's email
   */
  id: string;

  /**
   * Adds dependencies to the component
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for navigation to other route
   * @param activatedRoute - Gets the Query paramaters
   */
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private notify: NotificationService,
    private router: Router
  ) { }

  /**
   * Fetches the query parameters (token and id) from the URL
   * Initializes the passwordForm with the default values when component is initialized
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.token = params['token'];
        this.id = params['u'];
      }
    );
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    this.passwordForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.pattern(strongPassword)]],
      confirm_password: ['', Validators.required]
    },
    {
      validator: MustMatch('new_password', 'confirm_password')
    });
  }

  /**
   * Getter for all the controls of the signupForm
   */
  get f(): FormGroup['controls'] {
    return this.passwordForm.controls;
  }

  /**
   * Resets password through the API endpoint
   */
  resetPassword(): void {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }

    this.isLoading = true;

    const pwd = {
      user_id: this.id,
      token: this.token,
      new_password: this.passwordForm.get('new_password')?.value
    };

    this.api.putNoAuth('/users/reset-pwd', JSON.parse(JSON.stringify(pwd))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.passwordForm.reset();
          this.notify.showSuccess('Password has been reset successfully.');
          this.router.navigate(['/login']);
          this.isLoading = false;
          this.submitted = false;
        } else {
          this.notify.showError(res.detail);
          this.isLoading = false;
        }
      },
      err => {
        this.notify.showError(err.detail);
        this.isLoading = false;
      }
    );
  }

  /**
   * Toggles the password input type from text to password and vice versa when user clicks the show password icon
   */
  toggle(): void {
    this.fieldTextType = !this.fieldTextType;
  }

}
