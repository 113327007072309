<div class="reset">
    <div class="container">
        <form [formGroup]="passwordForm">
            <div class="form-group col-md-6 mb-2">
                <label for="new_password">New Password</label>
                <div class="input-group">
                    <input [type]="fieldTextType? 'text' : 'password'" class="form-control"
                        formControlName="new_password"
                        [ngClass]="{ 'is-invalid': submitted && f['new_password'].errors}" autofocus>
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <mat-icon class="icon" *ngIf="fieldTextType" (click)="toggle()">visibility</mat-icon>
                            <mat-icon class="icon" *ngIf="!fieldTextType" (click)="toggle()">visibility_off</mat-icon>
                        </span>
                    </div>
                    <div *ngIf="submitted && f['new_password'].errors" class="invalid-feedback">
                        <div *ngIf="f['new_password']['errors']required">New password is required</div>
                        <div *ngIf="f['new_password']['errors']pattern">
                            <p>Password must have a minimum of 8 characters.</p>
                            <p>Password must have at least one uppercase letter, one lowercase letter, one digit and one
                                special character.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-2">
                <label for="confirm-password">Confirm Password</label>
                <div class="input-group">
                    <input [type]="fieldTextType? 'text' : 'password'" class="form-control"
                        formControlName="confirm_password"
                        [ngClass]="{ 'is-invalid': submitted && f['confirm_password'].errors}">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <mat-icon class="icon" *ngIf="fieldTextType" (click)="toggle()">visibility</mat-icon>
                            <mat-icon class="icon" *ngIf="!fieldTextType" (click)="toggle()">visibility_off</mat-icon>
                        </span>
                    </div>
                    <div *ngIf="submitted && f['confirm_password'].errors" class="invalid-feedback">
                        <div *ngIf="f['confirm_password']['errors']required">Confirmation of new password is required
                        </div>
                        <div *ngIf="f['confirm_password']['errors']mustMatch">Passwords must match</div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 buttons">
                <button class="btn btn-info btn-block" (click)="resetPassword()" [disabled]="isLoading">RESET PASSWORD
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </div>
        </form>
    </div>
</div>