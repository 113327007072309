import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  /**
   * Form with email input field where the reset link will be sent
   */
  passwordForm: FormGroup;
  /**
   * Used for form validation and displaying validations errors
   */
  submitted = false;
  /**
   * Used to display spinner when the register button is clicked
   */
  isLoading = false;

  /**
   * Injects dependencies into the component.
   * Adds dependencies to the component
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   */

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService
  ) { }

  /**
   * Initalizes the passwordForm with the default values when the component is initialized
   */
  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  /**
   * Getter for all the controls of the passwordForm
   */
  get f(): FormGroup['controls'] {
    return this.passwordForm.controls;
  }

  /**
   * Sends an email to the user with a link to reset the password
   */
  requestPasswordReset(): void {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.api.postNoAuth('/users/send-reset-pwd-email', this.passwordForm.value).subscribe(
      res => {
        if (res.status_code === 200) {
          this.passwordForm.reset();
          this.isLoading = false;
          this.submitted = false;
          this.notify.showSuccess('Check email for link to reset your password.');
          
        } else {
          this.notify.showError(res.detail);
          this.isLoading = false;
        }
      },
      err => {
        this.notify.showError(err);
        this.isLoading = false;
      }
    );
  }
}
