import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../core/services/api.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  /**
   * Stores the username and password in form data format as required by the API endpoint
   */
  loginData: FormData;
  /**
   * The log in form.
   */
  loginForm: FormGroup;
  /**
   * Used to display spinner when the login button is clicked
   */
  isLoading = false;
  /**
   * Used for form validation and displaying errors
   */
  submitted = false;
  /**
   * Used on the password fields to hide or show passwords
   */
  fieldTextType: boolean;
  /**
   * Displayed when password is hidden
   */
  eyeIcon = faEye;
  /**
   * Displayed when password is not hidden
   */
  eyeSlashIcon = faEyeSlash;

  /**
   * Adds dependencies to the component
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for navigation to other route
   * @param authService - Authentication Service that contains the authentication information
   */
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private api: ApiService,
    private notify: NotificationService,
    private analyticsService: AnalyticsService
  ) { }
  
  /**
   * Initializes the loginForm with the default values when component is iniatilized
   */
  ngOnInit(): void {
    this.analyticsService.trackEvent('Login page Loaded', 'Login page Loaded into view', 'User accessed login page');
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(strongPassword)]]
    });
  }

  /**
   * Getter for all the controls of the signupForm
   */
  get f(): FormGroup['controls'] {
    return this.loginForm.controls;
  }

  /**
   * Logs in the user through the API endpoint
   */
  login(): void {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    const loginData = new FormData();
    loginData.append('username', this.loginForm.get('email')?.value);
    loginData.append('password', this.loginForm.get('password')?.value);

    this.api.postFormData('/users/login', loginData).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.handleSuccessfulLogin(res.detail);
        } else {
          this.notify.showError(res.detail);
          this.isLoading = false;
        }
      },
      error: (e) => {
        console.error(e);
        this.isLoading = false;
      }
    });
  }

  private handleSuccessfulLogin(detail: any): void {
    const { role, _id, must_change_password, firstLoginAttempt } = detail;

    localStorage.setItem('token', this.authService.encrypt(detail.token));
    localStorage.setItem('role', role);
    localStorage.setItem('userID', this.authService.encrypt(_id));
    this.authService.authenticate(_id);
    this.authService.getUnreadCount();
    this.isLoading = false;

    if (must_change_password) {
      this.router.navigate(['/change-password']);
      return;
    }

    if (role === 'freelancer') {
      this.authService.role = 'freelancer';
      this.router.navigate(firstLoginAttempt ? ['/profile'] : ['/find-work']);
      return;
    }

    if (role === 'client') {
      this.authService.role = 'client';
      const redirectUrl = this.authService.redirectUrl || '/my-jobs';
      this.router.navigate([redirectUrl], {
        state: {
          _id: this.authService._id,
          firstname: this.authService.firstname,
          lastname: this.authService.lastname,
        }
      });
      return;
    }

    if (role === 'admin') {
      this.authService.role = 'admin';
      this.router.navigate(['/admin/users']);
    }

    this.clearAuthServiceRedirectData();
  }

  private clearAuthServiceRedirectData(): void {
    this.authService.redirectUrl = '';
    this.authService._id = '';
    this.authService.firstname = '';
    this.authService.lastname = '';
  }


  /**
   * Toggles the password input type from text to password and vice versa when user clicks the show password icon
   */
  toggle(): void {
    this.fieldTextType = !this.fieldTextType;
  }
}
