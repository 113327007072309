<div class="container">
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="loginForm">
                <div class="form-group col-md-6 mb-3">
                    <label for="email">Email *</label>
                    <input type="text" class="form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f['email'].errors}" autofocus>
                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email']['errors']required">Email is required</div>
                        <div *ngIf="f['email']['errors']pattern">Input a valid email in lowercase</div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="password">Password *</label>
                    <div class="input-group">
                        <input [type]="fieldTextType? 'text' : 'password'" class="form-control"
                            formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors}">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <fa-icon [icon]="eyeIcon" *ngIf="fieldTextType" (click)="toggle()"></fa-icon>
                                <fa-icon [icon]="eyeSlashIcon" *ngIf="!fieldTextType" (click)="toggle()"></fa-icon>
                            </span>
                        </div>
                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                            <div *ngIf="f['password']['errors']required">Password is required</div>
                            <div *ngIf="f['password']['errors']minlength">Must be a minimum of 8 characters</div>
                            <div *ngIf="f['password']['errors']pattern">
                                <p>
                                    Password must have at least one uppercase letter, one lowercase letter, one digit and
                                    one special character.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6 buttons">
                    <a class="mb-2" routerLink="/forgot-password">Forgot Password?</a>
                    <button type="submit" class="btn btn-info flex-fill" (click)="login()" [disabled]="isLoading">LOG IN
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>