<div class="container">
  <div class="row selection" *ngIf="!roleSelected">
    <h2 class="mb-5 text-center">Join as a client or freelancer</h2>
    <div class="col-md-6 client">
      <div class="card" style="width: 18rem;" (click)="selectRole('client')">
        <h4>I am a client, hiring for a project</h4>
      </div>
    </div>
    <div class="col-md-6 freelancer">
      <div class="card" style="width: 18rem;" (click)="selectRole('freelancer')">
        <h4>I am a freelancer, looking for work</h4>
      </div>
    </div>
  </div>
  <div class="row register" *ngIf="roleSelected">
    <h4 class="mb-3">You are joining as a {{roleSelected}}.</h4>
    <div class="col-md-12">
      <form [formGroup]="signupForm">
        <div class="row">
          <div class="col-md-3 mb-3">
            <label for="firstname">Firstname *</label>
            <input type="text" class="form-control" formControlName="firstname" [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors}">
            <div *ngIf="submitted && f['firstname'].errors" class="invalid-feedback">
              <div *ngIf="f['firstname']['errors']required">Firstname is required</div>
            </div>
          </div>
          <div class="col-md-3">
            <label for="lastname">Lastname *</label>
            <input type="text" class="form-control" formControlName="lastname" [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors}">
            <div *ngIf="submitted && f['lastname'].errors" class="invalid-feedback">
              <div *ngIf="f['lastname']['errors']required">Lastname is required</div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <label for="email">Email *</label>
          <input type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors}">
          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email']['errors']required">Email is required</div>
            <div *ngIf="f['email']['errors']pattern">Input a valid email in lowercase</div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <label for="password">Password *</label>
          <div class="input-group">
              <input [type]="fieldTextType? 'text' : 'password'" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors}">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <fa-icon [icon]="eyeIcon" *ngIf="fieldTextType" (click)="toggle()"></fa-icon>
                  <fa-icon [icon]="eyeSlashIcon" *ngIf="!fieldTextType" (click)="toggle()"></fa-icon>
                </span>
              </div>
              <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                <div *ngIf="f['password']['errors']required">Password is required</div>
                <div *ngIf="f['password']['errors']pattern">
                  <p>Password must have a minimum of 8 characters.</p>
                  <p>Password must have at least one uppercase letter, one lowercase letter, one digit and one special character.</p>
                </div>
              </div>
          </div>
        </div>
        <div class="col-md-6 mb-2">
          <label for="password">Confirm Password *</label>
            <div class="input-group">
              <input [type]="fieldTextType? 'text' : 'password'" class="form-control" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors}">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <fa-icon [icon]="eyeIcon" *ngIf="fieldTextType" (click)="toggle()"></fa-icon>
                  <fa-icon [icon]="eyeSlashIcon" *ngIf="!fieldTextType" (click)="toggle()"></fa-icon>
                </span>
              </div>
              <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                <div *ngIf="f['confirmPassword']['errors']mustMatch">Passwords must match</div>
              </div>
            </div>
        </div>
        <div class="col-md-6 mb-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="terms" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': submitted && f['acceptTerms'].errors}">
            <label class="form-check-label" for="terms">
              I agree with the <a routerLink="/terms-and-conditions" target="_blank">Terms and conditions</a> and <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>.
            </label>
            <div *ngIf="submitted && f['acceptTerms'].errors" class="invalid-feedback">
              <div *ngIf="f['acceptTerms']['errors']required">You must agree before submitting.</div>
            </div>
          </div>
        </div>
        <div class="col-md-6 buttons d-flex">
            <button type="submit" class="btn btn-info flex-fill" (click)="signup()" [disabled]="isLoading">SIGN UP
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
      </form>
    </div>
  </div>
</div>