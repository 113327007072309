import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { MustMatch } from '../../../shared/custom-validators/mustMatch';
import { AnalyticsService } from '../../../core/services/analytics.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit{
  /**
   * The register form.
   */
  signupForm: FormGroup;
  /**
   * Used to display spinner when the register button is clicked
   */
  isLoading = false;
  /**
   * Used for form validation and displaying errors
   */
  submitted = false;
  /**
   * Used on the password fields to hide or show passwords
   */
  fieldTextType: boolean;
  /**
   * FontAwesome Eye Icon
   */
  eyeIcon = faEye;
  /**
   * FontAwesome Eye Slash Icon
   */
  eyeSlashIcon = faEyeSlash;
  /**
   * FontAwesome File Import Icon
   */
  fileImport = faFileImport;
  /**
   * FontAwesome brain icon
   */
  code = faBrain;
  roleSelected: string = '';

  /**
   * Adds dependencies into the component.
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for navigation to other route
   */
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private analyticsService: AnalyticsService
  ) { }

  /**
   * Initializes the signupForm with the default values when component is initialized
   */
  ngOnInit(): void {
    this.analyticsService.trackEvent('Sign up page Loaded', 'Sign up page Loaded into view', 'User accessed sign up page');
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    this.signupForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      role: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]],
      password: ['', [Validators.required, Validators.pattern(strongPassword)]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  /**
   * Getter for all the controls of the signupForm
   */
  get f(): FormGroup['controls'] {
    return this.signupForm.controls;
  }

  selectRole(role: string): void {
    this.roleSelected = role;
  }

  /**
   * Registers a user through the register user API endpoint
   */
  signup(): void {
    this.submitted = true;

    this.signupForm.patchValue({
      role: this.roleSelected
    });

    if (this.signupForm.invalid) {
      return;
    }

    this.isLoading = true;
      
    this.api.postNoAuth('/users/register', this.signupForm.value).subscribe({
      next: (res) => {
        if (res.status_code === 200) {
          this.signupForm.reset();
          this.submitted = false;
          this.isLoading = false;
          this.notify.showSuccess('You have successfully registered. Check your email for a confirmation.');
        } else {
          this.notify.showError(res.detail);
          console.error(res.detail);
          this.isLoading = false;
        }
      },
      error: (e) => {
        console.error(e);
        this.isLoading = false;
      }
    });
    this.analyticsService.trackEvent('Signup button clicked', 'Signup button clicked', 'User clicked sign up button');
  }

  /**
   * Toggles the password input type from text to password and vice versa when user clicks the show password icon
   */
  toggle(): void {
    this.fieldTextType = !this.fieldTextType;
  }
}
