<div class="reset">
    <div class="container">
        <form [formGroup]="passwordForm">
            <div class="form-group col-md-6">
                <label for="email">Email</label>
                <input type="text" class="form-control" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors}" autofocus>
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email']['errors']required">Email is required</div>
                </div>
            </div>
            <div class="form-group col-md-6 buttons">
                <button class="btn btn-info btn-block" (click)="requestPasswordReset()" [disabled]="isLoading">RESET
                    PASSWORD
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </div>
        </form>
    </div>
</div>